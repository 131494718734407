import axios from "axios";

const baseURL = 'https://dzywzefkgj.execute-api.ap-northeast-1.amazonaws.com/dev/disable_user';

const disableUser = async (token: string) => {
  // workaround
  axios.defaults.headers.common['Content-Type'] = '';
  const response = await axios.post(baseURL, {}, { headers: { Authorization: token } });
  console.log(response.data);
  return response.data;
};

export default disableUser;
