import * as React from 'react';

const stripePricingTableID = process.env.NODE_ENV === 'development' ? "prctbl_1NE5Z6K9VecQkpg1E1EQXOZf" : "prctbl_1NKIHyK9VecQkpg14YBkSpaF";
const stripePublishableKey = process.env.NODE_ENV === 'development' ? "pk_test_51JQPZPK9VecQkpg1vf8uWqKQDycu6zzTY1iNtltXAAQCzLXVKdqGfDZBh7ZWmlSyRyH86zLTXFmYm2HKmojxV4w600UDy5Yt0j" : "pk_live_51JQPZPK9VecQkpg1E05noyScB8Nw7pcfkaraw6Lgq06gYORxnYbv4UnOOu7Aiy5uBq1Qb5HTc9mQiYvThDoIyMiz00I0PtUcQ9";

function PricingPage({userId, email}: {userId: string, email: string}) {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    // @ts-ignore
    <stripe-pricing-table
      pricing-table-id={stripePricingTableID}
      publishable-key={stripePublishableKey}
      client-reference-id={userId}
      customer-email={email}
    />
  );
}

export default PricingPage;
