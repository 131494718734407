import axios from "axios";

const baseURL = 'https://dzywzefkgj.execute-api.ap-northeast-1.amazonaws.com/dev/get_user_purchases';

const getUserPurchases = async (token: string) => {
  return await axios.get(baseURL, { headers: { Authorization: token } }).then((response) => {
    console.log(response.data);
    return response.data;
  });
};

export default getUserPurchases;
